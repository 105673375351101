//import { ChainId } from '@uniswap/sdk'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'

import styled from 'styled-components'

import Logo from '../../assets/svg/logo.png'
import LogoDark from '../../assets/svg/logo_white.svg'
// import WordmarkDark from '../../assets/svg/wordmark_white.svg'
// import Wordmark from '../../assets/svg/wordmark.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import { YellowCard } from '../Card'
import Settings from '../Settings'
import Menu from '../Menu'

import { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
//import VersionSwitch from './VersionSwitch'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  background-color: black;
  border-bottom: 1px dotted yellow;
  top: 0;
  
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 4px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    
`};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  margin-right: 20px;

  :hover {
    cursor: pointer;
  }
`
const Anchor = styled.a`
  text-decoration: none;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

// const TitleText = styled(Row)`
//   width: fit-content;
//   white-space: nowrap;
//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     display: none;
//   `};
// `

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 25px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 6px solid blue;
  }
`

// const TestnetWrapper = styled.div`
//   white-space: nowrap;
//   width: fit-content;
//   margin-left: 10px;
//   pointer-events: auto;
// `

// const NetworkCard = styled(YellowCard)`
//   width: fit-content;
//   margin-right: 10px;
//   border-radius: 25px;
//   padding: 8px 12px;
// `
const NetworkCar = styled(YellowCard)`
  white-space: nowrap;
  width: fit-content;
  margin: 0px 10px;
  border-radius: 10px;
  padding: 8px 12px;
  background-color: #fede00;
  color: #964b00;
  // Green neon shadow effect
  box-shadow:
    0 0 3px #fede00,  // Light green glow
    0 0 6px #fede00,  // Light green glow
    0 0 9px #964b00,  // Light green glow
    0 0 12px #964b00,  // Light green glow
    0 0 15px #964b00,  // Light green glow
    0 0 10px #964b00,  // Light green glow
    0 0 3px #964b00; // Light green glow

  :hover {
    cursor: pointer;
  }
`
// const NetworkCarGray = styled(YellowCard)`
//   white-space: nowrap;
//   width: fit-content;
//   margin: 0px 10px;
//   border-radius: 8px;
//   padding: 8px 12px;
//   background-color: #131010;
//   color: rgba(255,255,255,0.4);
//   border: solid 1px #808080;
//   :hover {
//     cursor: pointer;
//   }
// `

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img { 
      width: 4.5rem;
    }
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    align-items: center;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

// const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
//   [ChainId.MAINNET]: null,
//   [ChainId.RINKEBY]: 'Rinkeby',
//   [ChainId.ROPSTEN]: 'Ropsten',
//   [ChainId.GÖRLI]: 'Görli',
//   [ChainId.KOVAN]: 'Kovan',
//   [ChainId.ZETA]: 'Zetachain'
// }

export default function Header() {
  const { account } = useActiveWeb3React()
  // const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()

  return (
    <HeaderFrame>
      <RowBetween style={{ alignItems: 'center' }} padding="1rem 1rem 0 1rem">
        <HeaderElement>
          <Title href="http://bangswap.xyz/">
            <UniIcon>
              <img src={isDark ? LogoDark : Logo} alt="logo" width={60} />
            </UniIcon>
            <h1 style={{ marginLeft: '-19px', marginTop: '13px', fontFamily: "Inter", fontSize: '15px', color: '#fede00'}}>ANG</h1>
          </Title>
          {!isMobile && (<div style={{ display: "flex" }}>
            <Anchor href='http://bangswap.xyz' ><NetworkCar>LEVERAGE YIELD FARM</NetworkCar></Anchor>
            <Anchor href='http://bangswap.xyz'><NetworkCar>FARM</NetworkCar></Anchor>
            {/* <Anchor href='http://bangswap.xyz'><NetworkCar>LOCKER</NetworkCar></Anchor> */}
            <Anchor href='http://bangswap.xyz'><NetworkCar>SPACEPAD</NetworkCar></Anchor>
            <Anchor href='http://bangswap.xyz'><NetworkCar>LOTTERY</NetworkCar></Anchor>
            {/* <Anchor href=''><NetworkCarGray>CrossChain-Swap</NetworkCarGray></Anchor> */}
          </div>)
          }
        </HeaderElement>
        <HeaderControls>
          <HeaderElement>

            {/* <TestnetWrapper>
              {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
            </TestnetWrapper> */}

            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} ETH
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            <Settings />
            <Menu />
          </HeaderElementWrap>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
