import React, { useRef } from 'react'
//import { BookOpen, MessageCircle, CloudRain, Pocket, Twitter } from 'react-feather'
import styled from 'styled-components'
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import useToggle from '../../hooks/useToggle'

import { ExternalLink } from '../../theme'

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};

  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 18.125rem;
  min-height: 80vh;
 
  background-color: #964b00;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  right: -2rem;
  z-index: 100;
`

const MenuItem = styled(ExternalLink)`
  background-color: #964b00;
  padding: 1rem 0.8rem;
  margin-bottom: 0.5rem;
  border: none;
  border-radius: 8px;
  
  color: #fede00;
  :hover {
    background-color: #fede00;
    color: #964b00;
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`
// const MenuItemGray = styled(ExternalLink)`
//   background-color: #808080;
//   padding: 0.8rem 0.5rem;
//   margin-bottom: 0.5rem;
//   border: none;
//   border-radius: 8px;

//   color: rgba(0,0,0,0.4);
//   :hover {
//     background-color: #808080;
//     color: rgba(0,0,0,0.4);
//     cursor: none;
//     text-decoration: none;
//   }
//   > svg {
//     margin-right: 8px;
//   }
// `



//const CODE_LINK = 'https://github.com/Uniswap/uniswap-interface'

export default function Menu() {
  const node = useRef<HTMLDivElement>()
  const [open, toggle] = useToggle(false)

  useOnClickOutside(node, open ? toggle : undefined)

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <StyledMenuIcon />
      </StyledMenuButton>
      {open && (
        <MenuFlyout>
          {/* <MenuItem id="link" href="https://uniswap.org/">
            <Info size={14} />
            About
          </MenuItem> */}

          <MenuItem id="link" href="https://bangswap.xyz">
            {/* <CloudRain size={14} /> */}
            Farm
          </MenuItem>
          <MenuItem id="link" href="https://bangswap.xyz">
            {/* <CloudRain size={14} /> */}
            Leverage Yield Farm
          </MenuItem>

          <MenuItem id="link" href="https://bangswap.xyz">
            {/* <Pocket size={14} /> */}
            SpacePad
          </MenuItem>
          <MenuItem id="link" href="https://bangswap.xyz">
            {/* <Pocket size={14} /> */}
            Lottery
          </MenuItem>

          <MenuItem id="link" href="https://bangswap.gitbook.io/bangswap/">
            {/* <BookOpen size={14} /> */}
            Docs
          </MenuItem>

          {/* <MenuItem id="link" href={CODE_LINK}>
            <Code size={14} />
            Code
          </MenuItem> */}
          <MenuItem id="link" href="https://t.me/BangSwap">
            {/* <MessageCircle size={14} /> */}
            Telegram
          </MenuItem>
          <MenuItem id="link" href="https://x.com/BangSwap?t=USPesum_Oqs3dB7zRA7yVw&s=08">
            {/* <Twitter size={14} /> */}
            Twitter
          </MenuItem>
          {/* <MenuItemGray id="link" href="">
            <RefreshCw size={14} />
            CrossChain Swap
          </MenuItemGray>
          <MenuItemGray id="link" href="">
            <FastForward size={14} />
            Perp Dex
          </MenuItemGray> */}

          {/* <MenuItem id="link" href="https://uniswap.info/">
            <PieChart size={14} />
            Analytics
          </MenuItem> */}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
